



















































import {Component, Ref, Vue} from "vue-property-decorator";
import ProductCategory from "@/models/ProductCategory";
import ProductCategoryService from "@/services/ProductCategoryService";
import {getModule} from "vuex-module-decorators";
import DialogModule from "@/store/DialogModule";
import Dialog from "@/models/vue/Dialog";
import ProductFeatureType, {ProductFeatureTypeEnum} from "@/models/ProductFeatureType";
import ProductFeatureTypeService from "@/services/ProductFeatureTypeService";

@Component
export default class ProductFeatureTypesView extends Vue {
    @Ref() readonly form!: HTMLFormElement
    productFeatureTypes: ProductFeatureType[] = []
    page: number = 1
    pageCount: number = 0
    loading: boolean = false
    search: string = ""
    productFeatureType: ProductFeatureType = new ProductFeatureType()
    headers = [
        { text: "Id", value: "id", width: "120px" },
        { text: "Nombre", value: "name", width: "200px" },
        { text: "Tipo", value: "type", width: "200px" },
        { text: "Magnitud", value: "magnitude", width: "200px" },
    ]
    dialog: boolean = false


    created() {
        ProductFeatureTypeService.getProductFeatureTypes(this, this.productFeatureTypes)
    }

    rowClick(productFeatureType: ProductFeatureType) {
        this.$router.push({path: "/product-feature-types/" + productFeatureType.id})
    }

    openUrl(url: string) {
        window.open(url, "_blank");
    }

    openCreateDialog() {
        if (this.form) this.form.resetValidation()
        this.dialog = true
        this.productFeatureType = new ProductFeatureType()
    }

    createProductFeature() {
        if (this.form.validate()) {
            getModule(DialogModule).showDialog(new Dialog(
                "Aviso",
                "¿Está seguro de crear la característica?",
                () => ProductFeatureTypeService.postProductFeatureType(this, this.productFeatureType)
            ))
        }
    }

    typeToName(type: ProductFeatureTypeEnum) {
        return ProductFeatureTypeService.typeToName(type)
    }

}
